const track = ({ event, label, category, value }) => {
    // console.log(`tracking event {${event}, ${label}, ${category}, ${value}}`);
    window.gtag('event', event, {
        event_label: label,
        event_category: category,
        value: value,
    });
}
export default function useEventTracking(event, label, category, value) {
    if (event) {
        track({ event, value, category, label })
    }
    return { track }
}