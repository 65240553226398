import { Link } from "react-router-dom";
import useObituary from "../../../../hooks/ethereum/nft/skele/useObituary";
import useSkeleTokens from "../../../../hooks/ethereum/nft/skele/useSkeleTokens";
import useSkelephunks from "../../../../hooks/ethereum/nft/skele/useSkelephunks";
import defined from "../../../../util/defined";
import Thumbnail from "./elements/Thumbnail";
import useSkeleKeys from "../../../../hooks/ethereum/nft/skele/useSkeleKeys";
import { toTitleCase } from "../../../../util/StringUtils";
import ActionButton from "./elements/ActionButton";
import LinkButton from "./elements/LinkButton";

export default function Member({ address }) {
    const { obituary } = useObituary();
    const tag = obituary.getName(address)
    const tagline = obituary.getProfileItem(address, 'tagline')
    const tokenID = obituary.getToken(address)
    const { skeleTokens, catacombs } = useSkeleTokens();
    const balance = skeleTokens.balanceOf(address)
    const catBalance = catacombs.balanceOf(address)
    const { skeleKeys: keys } = useSkeleKeys();
    const keysBalance = keys.balanceOf(address);
    // if (!tokenID) return address
    return (<div className="member">
        <div className="member-lockup" >
            <div className="myskele">
                <Link to={`/community/${tag}`}>
                    <Thumbnail label={false} icon={false} tokenID={tokenID} collection={catacombs} />
                </Link>
            </div>
            <div className="member-info" style={{ display: 'flex', flexDirection: 'column', rowGap: '.5rem' }}>
                <div className="skeletag">{tag?.toUpperCase().replaceAll('-', ' ')}</div>
                <div style={{
                    fontSize: '.75rem', lineHeight: '.75rem', margin: 0, padding: 0, textAlign: 'left'
                }} className="mint-info">{toTitleCase(tagline?.toString())}</div>
                {defined(balance, keysBalance, catBalance) &&
                    <div style={{
                        padding: '.25rem',
                        background: 'rgba(0,0,0,1)',
                        border: '1px solid rgba(255,255,255,.25',
                        borderRadius: '1rem',
                        display: 'flex',
                        fontSize: '1.25rem',
                        lineHeight: '1.5rem',
                        columnGap: '.5rem'
                    }}>
                        {balance > 0 && <Link to={`/wallet/${tag}`}><div title="Holder">💀</div></Link>}
                        {keysBalance > 0 && <Link to={`/wallet/${tag}`}><div title="Key Holder">🗝️</div></Link>}
                        {balance >= 100 && <Link to={`/wallet/${tag}`}><div title="100+ Club">💯</div></Link>}
                        {catBalance > 1 && <Link to={`/wallet/${tag}`}><div title="Catacomber">⚰️</div></Link>}
                    </div>}
                {defined(balance) && <LinkButton to={`/wallet/${tag}`} label={`${balance} skelephunk${balance == 1 ? '' : 's'}`} />}
            </div>
        </div>
    </div>)
}