import GAD from "../../../../util/GAD";
import defined from "../../../../util/defined";
import getENV from "../../../../util/getENV";
import useNFTCollection from "../useNFTCollection";
import useSkelephunks from "./useSkelephunks";

export default function useCatacombs() {
    //console.log('>>> useCatacombs')
    const { modules: { catacombs: { contract } } } = getENV();
    const { skelephunks: catacombs } = useSkelephunks(contract);
    const getTokenInfo = catacombs.getTokenInfo;
    catacombs.getTokenInfo = (id) => {
        const tokenInfo = getTokenInfo(id);
        return {
            ...tokenInfo,
            lockedAt: catacombs.lockedAt(id)
        }
    }
    return { catacombs }
}