import {
    useAccount,
    useEnsAddress,
} from "wagmi";

import getENV from '../../../util/getENV.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import Header from './modules/Header.js';
import Collection from './modules/Collection.js';
import useSkelephunks from '../../../hooks/ethereum/nft/skele/useSkelephunks.js';
import defined from '../../../util/defined.js';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import ConnectButton from './modules/elements/ConnectButton.js';
import useObituary from '../../../hooks/ethereum/nft/skele/useObituary.js';
import { normalize } from 'viem/ens';
import usePageTracking from '../../../hooks/usePageTracking.js';
import useSkeleKeys from '../../../hooks/ethereum/nft/skele/useSkeleKeys.js';
import useSkeleTokens from "../../../hooks/ethereum/nft/skele/useSkeleTokens.js";
import Thumbnail from "./modules/elements/Thumbnail.js";
export default function Wallet() {
    //console.log('>>> Wallet')
    usePageTracking();
    const { override, modules } = getENV();
    const account = useAccount();
    let isConnected, me;
    if (override) {
        isConnected = true;
        me = override
    } else {
        isConnected = account.isConnected;
        me = account.address;
    }
    const { walletID } = useParams();

    usePageTracking(`Wallet:`);
    const { obituary } = useObituary();
    let tagAddress;
    if (modules.obituary.contract.address) {
        tagAddress = obituary.getAddress(walletID);
    }
    const { status, data: address } = useEnsAddress({ name: normalize(walletID), chainId: 1 });
    const isMe = walletID == 'me' || (isConnected && (walletID === me || (me && address === me) || tagAddress == me));
    const isENS = !!address;
    const isTag = !!tagAddress;
    const wallet = isMe && isConnected ? me
        : isENS ? address
            : isTag ? tagAddress
                : walletID;
    const { skeleTokens } = useSkeleTokens();

    let pfp, tag;
    if (modules.obituary.contract.address) {
        pfp = obituary.getToken(wallet);
        tag = obituary.getName(wallet);
    }
    const { open } = useWeb3Modal();
    const balance = skeleTokens.balanceOf(wallet);
    const { skeleKeys: keys } = useSkeleKeys();
    const { balance: keysBalance, tokenIDs: keysList } = keys.tokensOfOwner(wallet);
    const numKeys = keysList?.length;
    const collector = isMe ? 'You'
        : isENS ? walletID
            : tag ? tag
                : walletID.substring(0, 13);
    return (
        <div className="landing  wf-section">
            <Header subtitle="The Collectoors" />
            <div id="inventory" className="inventory">
                <div className={pfp ? 'profile' : ''} style={{ gridRowGap: 0, alignItems: 'center' }}> {pfp && <Thumbnail icon={false} tokenID={pfp} collection={skeleTokens} label={false} />}
                    <div className="token-name free">{collector}</div></div>
                {walletID == 'me' && !isConnected && <ConnectButton />}
                {!!keysBalance && <>
                    <div className="div-block-18">
                        <h3 className="skeleton-keys">{isMe ? 'Are' : 'Is'} a Keyholder</h3>
                        <div className="keys-info">
                            <div>?</div>
                            <div className="key-info-text">
                                <div className="key-bubble">
                                    <div className="congratulations">Awesome!</div>
                                    <div>A skeleton key is rare skelephunks treasure.</div>
                                    {!!numKeys && <div>Click a key to learn how it works.</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Collection wallet={wallet} collection={keys} toggle={false} />
                </>
                }
                {defined(balance) && <>
                    <h3 className="skeleton-keys">
                        {!!keysBalance ? 'With' : isMe ? 'Have' : 'Has'} {balance} Skelephunk{balance == 1 ? '' : 's'}
                    </h3>
                    <Collection wallet={wallet} collection={skeleTokens} toggle={true} />
                    <div className="cta-info eth">reveal random new skelephunks</div>
                    <div className="top">
                        <Link onClick={() => { if (!isConnected) open(); }} to={'/mint'} className="cta">MINT YOURS NOW</Link>
                    </div>
                </>}
            </div>
        </div>)
}