import { useAccount } from "wagmi";
import getENV from "../../../util/getENV";
import { useRef, useState } from "react";
import useLocalState from "../../../hooks/useLocalState";
import Header from "./modules/Header";
import equiv from "../../../util/equiv";
import ConnectButton from "./modules/elements/ConnectButton";
import useRemains from "../../../hooks/ethereum/nft/skele/useRemains";
import { toTitleCase } from "../../../util/StringUtils";
import defined from "../../../util/defined";
import useOssuary from "../../../hooks/ethereum/nft/skele/useOssuary";
import GAD from "../../../util/GAD";
import useSkeleMarket from "../../../hooks/ethereum/nft/skele/useSkeleMarket";
import PFP from "./modules/elements/PFP";
import useYOLO from "../../../hooks/ethereum/nft/skele/useYOLO";
import TxInputButton from "./modules/elements/TxInputButton";
import RefInput from "./modules/elements/RefInput";
import FieldInput from "./modules/elements/FieldInput";
import useSkeleGun from "../../../hooks/useSkeleGun";

export default function Ossuary() {
    const { override, opensea, etherscan, modules } = getENV();
    //MODEL
    //account
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const [exchangeMode, setExchangeMode] = useLocalState(`exchange-mode-${wallet}`, 'forge');
    const setForgeMode = () => {
        setExchangeMode('forge');
        setExchangeQuantity(1);
    }
    const setShatterMode = () => {
        setExchangeMode('shatter');
        setExchangeQuantity(1);
    }
    const setMintMode = () => {
        setExchangeMode('mint');
    }
    const setYOLOMode = () => {
        setExchangeMode('yolo');
    }
    const { remains } = useRemains();
    const { ossuary } = useOssuary();
    const { yolo } = useYOLO();
    const { skeleMarket: market } = useSkeleMarket();
    const numBones = remains.bonesRemaining(wallet);
    const fragRef = useRef();
    const numFragments = remains.fragmentsRemaining(wallet);
    const fragChange = numFragments - (fragRef.current || numFragments);
    const fragDelta = (fragChange > 0 ? '+' : '') + fragChange;
    const maxForge = ossuary.maxForge(numFragments);
    const minForgeFragmentCost = ossuary.forgeCost(1);
    const maxBones = exchangeMode == 'forge' ? maxForge : numBones;
    const pooledBones = ossuary.getPooledBones();
    const pooledFragments = ossuary.getPooledFragments();
    const forgeRate = ossuary.forgeRate();
    const shatterRate = ossuary.shatterRate();
    const [exchangeQuantity, setExchangeQuantity] = useState(1)
    const inputQuantityOptionsJSX = [];
    const getInput = (bonesAmt) => {
        return exchangeMode == 'forge' ? ossuary.forgeCost(bonesAmt) : bonesAmt;
    }
    for (let i = maxBones; i > 0; i--) {
        const n = getInput(i)
        inputQuantityOptionsJSX.push(<option key={`input-${i}`} value={i}>{n} {exchangeMode == 'forge' ? '✨' : '🦴'}</option>);
    }

    const outputQuantityOptionsJSX = [];
    const getOutput = (bonesAmt) => {
        return exchangeMode == 'forge' ? bonesAmt : ossuary.shatterReward(bonesAmt);
    }
    for (let i = maxBones; i > 0; i--) {
        const n = getOutput(i);
        outputQuantityOptionsJSX.push(<option key={`output-${i}`} value={i}>{n} {exchangeMode !== 'forge' ? '✨' : '🦴'}</option>);
    }
    const onQuantityChange = (e) => {
        setExchangeQuantity(e.target.value);
    }
    //const SLIPPAGE = 
    const {
        write: exchange,
        status: exchangeStatus
    } = (exchangeMode == 'forge' ? ossuary.forgeMeBones : ossuary.shatterMyBones)(Number(exchangeQuantity), exchangeMode == 'forge' ? numFragments : 0);


    // MINT
    const [mintQty, setMintQty] = useState(1);
    const mintForBonesPrice = market.getMintForBonesPrice(1);
    const mintForBonesCost = market.getMintForBonesPrice(mintQty);
    const maxMintsForWallet = Math.floor(numBones / mintForBonesPrice);
    const mintQuantityOptionsJSX = [];
    for (let i = 1; i <= maxMintsForWallet; i++) {
        mintQuantityOptionsJSX.push(<option key={`qtyOption-${i}`} value={i}>{i}</option>);
    }
    const [genderToMint, setGenderToMint] = useState(GAD.genders[0]); //Math.floor(Math.random() * GAD.genders.length)]); 
    const [directionToMint, setDirectionToMint] = useState(GAD.directions[0]); //Math.floor(Math.random() * GAD.directions.length)]); 
    const gad = GAD.toNum(genderToMint, directionToMint);
    const onMintQuantityChange = (e) => {
        setMintQty(e.target.value);
    }
    const {
        write: mintForBones,
        status: mintStatus
    } = market.mintForBones(mintQty, gad, mintForBonesCost);
    for (let i = 1; i <= maxMintsForWallet; i++) {
        mintQuantityOptionsJSX.push(<option key={`qtyOption-${i}`} value={i}>{i}</option>);
    }
    const [wager, setWager] = useState(1);
    const onWagerChange = value => {
        if (value.length > 0) {
            const num = Math.max(1, Math.min(Number(value), numFragments));
            setWager(num);
        } else {
            setWager(value)
        }
    }
    const [multiplier, setMultiplier] = useState(2);
    const onMultiplierChange = e => {
        setMultiplier(e.target.value)
    }
    const odds = yolo.oddsForMultiplier(multiplier);
    const [num, setNum] = useState();
    const onNumberChange = value => {
        setNum(value);
    }
    const getRandom = () => {
        return Math.ceil(Math.random() * multiplier)
    }
    const number = defined(num) ? num > multiplier ? getRandom() : num : undefined;
    const pickRandom = () => {
        setNum(getRandom());
    }
    const {
        write: yoLo,
        status: yoloStatus
    } = yolo.yolo(wager, multiplier, number);
    const handleYolo = () => {
        fragRef.current = numFragments;
        yoLo();
    }
    const exchangePending = [exchangeStatus, mintStatus, yoloStatus].indexOf('loading') > - 1;
    const signPending = [exchangeStatus, mintStatus, yoloStatus].indexOf('signing') > - 1;
    return (
        <div id="mint" className={`landing wf-section`}>
            <Header subtitle="The Ossuary" />
            <div id="inventory" className="inventory wf-section">
                <h3 className="skeleton-keys">
                    {toTitleCase(exchangeMode)} {equiv(exchangeMode, 'forge') || equiv(exchangeMode, 'yolo') ? 'Fragments' : equiv(exchangeMode, 'shatter') ? 'Bones' : 'Skelephunks'} {equiv(exchangeMode, 'mint') ? 'using' : 'into'}{equiv(exchangeMode, 'yolo') ? ' More' : ''} {equiv(exchangeMode, 'shatter') || equiv(exchangeMode, 'yolo') ? 'Fragments' : 'Bones'}</h3>
                <div className="list" >
                    {((numFragments + numBones + maxMintsForWallet) > 0) && <div className="toggle">
                        {numBones >= 1 && <div onClick={setShatterMode} className={`button ${equiv(exchangeMode, 'shatter') ? 'active' : ''} w-button`}>shatter</div>}
                        {numFragments >= 1 && <div onClick={setYOLOMode} className={`button ${equiv(exchangeMode, 'yolo') ? 'active' : ''} w-button`}>yolo</div>}
                        {numFragments >= minForgeFragmentCost && <div onClick={setForgeMode} className={`button ${equiv(exchangeMode, 'forge') ? 'active' : ''} w-button`}>forge</div>}
                        {maxMintsForWallet >= 1 && <div onClick={setMintMode} className={`button ${equiv(exchangeMode, 'mint') ? 'active' : ''} w-button`}>mint</div>}
                    </div>
                    }
                    {defined(numBones, numFragments) &&
                        <div className="modify-note"
                            style={{ color: 'var(--grey)', fontSize: '1rem' }}>
                            <div>
                                You {exchangeMode == 'yolo' && fragChange != 0 ? 'now ' : ''}have {['forge', 'yolo'].indexOf(exchangeMode) > -1 ? `✨${numFragments} fragments` : `🦴${numBones} bones`}{exchangeMode == 'yolo' && fragChange != 0 ? <span style={{ color: fragChange > 0 ? 'green' : 'red' }}> ({fragDelta})</span> : ''}</div>
                        </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', justifyContent: 'stretch' }}>
                        {isConnected ? <>
                            {exchangeMode == 'yolo' ?
                                <div id="yolo-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

                                    <div className="profile-info" style={{ display: 'flex' }}>
                                        <div>Wager: {wager != numFragments && <>(<span className="eth" onClick={() => setWager(numFragments)}>max</span>)</>}</div>
                                        <FieldInput

                                            field="✨"
                                            type="number"
                                            style={{ color: 'var(--silver)', fontSize: '1rem' }}
                                            value={wager}
                                            onInput={onWagerChange}
                                            placeholder="enter a wager"
                                        />
                                    </div>
                                    <div className="profile-info" style={{ display: 'flex' }}>
                                        Win Multiplier:
                                        <select
                                            id="Quantity"
                                            name="Quantity"
                                            data-name="Quantity"
                                            required=""
                                            className="action w-button catacombs"
                                            style={{ fontSize: '1rem' }}
                                            onChange={onMultiplierChange}
                                            value={multiplier}
                                        >
                                            <option value={2}>2X</option>
                                            <option value={3}>3X</option>
                                            <option value={4}>4X</option>
                                            <option value={5}>5X</option>
                                            <option value={10}>10X</option>
                                            <option value={100}>100X</option>
                                            <option value={255}>255X</option>
                                        </select>
                                    </div>
                                    <div className="profile-info" style={{ display: 'flex' }}>
                                        <div>Pick a number 1 to {multiplier} {wager != numFragments && <>(<span className="eth" onClick={() => pickRandom()}>random</span>)</>}</div>
                                        <FieldInput
                                            type="number"
                                            min="1"
                                            max={multiplier}
                                            style={{ color: 'var(--silver)', fontSize: '1rem' }}
                                            value={number}
                                            onInput={onNumberChange}
                                            placeholder="enter a number"
                                        />
                                    </div>
                                    {defined(odds) && <div className="profile-info">
                                        <div style={{ color: 'var(--silver)', textAlign: 'center' }}> ⚠️ Odds to Win ✨{wager * multiplier}: 1 in {odds}</div>
                                        <div style={{ color: 'var(--silver)', textAlign: 'center' }}> (wager is nonrefundable)</div>
                                    </div>}
                                </div>
                                : exchangeMode == 'mint' ?
                                    <div id="mint-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <PFP
                                            gender={genderToMint}
                                            direction={directionToMint}
                                            setDirection={setDirectionToMint}
                                            help={true}
                                            setGender={setGenderToMint}
                                        />
                                        <div className="form"
                                            id="wf-form-Mint-Form"
                                            name="wf-form-Mint-Form"
                                            style={{ marginTop: '1rem' }}>
                                            <select
                                                style={{ width: '5rem' }}
                                                id="Quantity"
                                                name="Quantity"
                                                data-name="Quantity"
                                                required=""
                                                className="select-field w-select"
                                                onChange={onMintQuantityChange}
                                                value={mintQty}
                                            >
                                                {mintQuantityOptionsJSX}
                                            </select>
                                            <div className="profile-info" style={{ display: 'flex' }}>for 🦴{mintForBonesCost}</div>
                                        </div>
                                    </div>
                                    :
                                    <div id="exchange-card" style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', minWidth: '20rem' }}>
                                        <div>
                                            <div className="profile-info" style={{ display: 'flex' }}><div>{exchangeMode == 'forge' ? 'Fragments' : 'Bones'} In: {exchangeQuantity != maxBones && <>(<span className="eth" onClick={() => setExchangeQuantity(maxBones)}>max</span>)</>}</div>
                                                <select
                                                    id="Quantity"
                                                    name="Quantity"
                                                    data-name="Quantity"
                                                    required=""
                                                    className="action w-button catacombs"
                                                    style={{ fontSize: '1rem' }}
                                                    onChange={onQuantityChange}
                                                    value={exchangeQuantity}
                                                >
                                                    {inputQuantityOptionsJSX}
                                                </select>
                                            </div>
                                            <div className="profile-info" style={{ display: 'flex' }}>
                                                {exchangeMode != 'forge' ? 'Fragments' : 'Bones'} Out:
                                                <select
                                                    id="Quantity"
                                                    name="Quantity"
                                                    data-name="Quantity"
                                                    required=""
                                                    className="action w-button catacombs"
                                                    style={{ fontSize: '1rem' }}
                                                    onChange={onQuantityChange}
                                                    value={exchangeQuantity}
                                                >
                                                    {outputQuantityOptionsJSX}
                                                </select>
                                            </div>
                                        </div>
                                        {defined(pooledBones, pooledFragments) &&
                                            <div className="modify-note" style={{ color: 'var(--silver)', fontSize: '1rem' }}>
                                                <div>Pool: {`🦴${pooledBones} | ✨${pooledFragments}`}</div>
                                            </div>
                                        }
                                    </div>
                            }
                            <button onClick={exchangeMode == 'yolo' ? handleYolo : exchangeMode == 'mint' ? mintForBones : exchange} className="mint-button ">
                                {exchangePending ? `${exchangeMode.toUpperCase()}ING…` : signPending ? 'PLEASE SIGN' : ((exchangeMode !== 'yolo' && exchangeQuantity == maxBones) || (exchangeMode == 'yolo' && wager == numFragments) ? 'Max ' : '') + exchangeMode.toUpperCase()}
                            </button>
                        </> : <ConnectButton />}
                    </div>
                </div>
            </div>
        </div >
    )
}