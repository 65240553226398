import getENV from "../util/getENV";
import Gun from 'gun/gun'
// eslint-disable-next-line no-unused-vars
import SEA from 'gun/sea'
import 'gun/lib/radix';
import 'gun/lib/radisk';
import 'gun/lib/store';
import rindexed from 'gun/lib/rindexed';
import useRedraw from "./useRedraw";

const { gun: { peer, key, node } } = getENV();
const gun = Gun({
    peers: peer,
    localStorage: false,
    file: 'skele-sep',
    axe: false
});
const INFO_KEYS = ['tokenURI', 'tokenId', 'minterOf', 'mintedAt', 'genderAndDirection'];
const META_KEYS = ['name', 'description', 'image'];
const tokens = [];
export default function useSkeleGun() {
    const { redraw } = useRedraw();
    const getToken = (tokenId) => {
        gun.get('~' + key).get(node).get(tokenId).on((data) => {
            const token = {};
            const metadata = {};
            const traits = {};
            for (let key in data) {
                if (INFO_KEYS.includes(key)) token[key] = data[key];
                else if (META_KEYS.includes(key)) metadata[key] = data[key];
                else traits[key] = data[key]
            }
            metadata.traits = traits;
            token.metadata = metadata;
            tokens[tokenId] = token;

        });
        return tokens[tokenId];
    }
    return { getToken };

}