//
// Author: Skelephunks 
//
import { createWeb3Modal } from '@web3modal/wagmi/react'




import getENV from '../../util/getENV.js';
import ContractsMenu from './screens/modules/elements/ContractsMenu.js';
import BRB from './screens/modules/BRB.js';
import AppRouter from './AppRouter.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { WagmiProvider, deserialize, serialize, useWatchBlockNumber } from 'wagmi'
import useRedraw from '../../hooks/useRedraw.js';


const { projectId, status: { network: networkStatus }, override, config: { wagmi: wagmiConfig } } = getENV();



createWeb3Modal({ wagmiConfig, projectId });

// 1. Import modules.
// 2. Create a new Query Client with a default `gcTime`.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1_000 * 60 * 60 * 24, // 24 hours
    },
  },
})

// 3. Set up the persister.
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})
export default function App() {
  //console.log('>>> App')
  console.log(`//////////////// STARTING APP /////////////////`)
  return (<>
    <ContractsMenu />
    <div className="home">
      {!networkStatus ?
        <BRB /> :
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{ persister }}
            >
              <AppRouter />
            </PersistQueryClientProvider>

          </WagmiProvider>
        </QueryClientProvider>
      }
    </div>
  </>)
}
