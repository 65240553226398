import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { telos } from "viem/chains";
let last;
const usePageTracking = (title) => {
  const location = useLocation();

  useEffect(() => {
      const path = location.pathname + location.search
    // track pageview with gtag / react-ga / react-ga4, for example:
    if (last !== path) {
      window.gtag("event", "page_view", {
        page_path: path,
        page_title: title,
      });
      last = path;
    }
  }, [location]);
};

export default usePageTracking;