import { useState } from "react";
import NFTOverlay from "./NFTOverlay.js";
import Thumbnail from "./elements/Thumbnail.js";
import useCatacombs from "../../../../hooks/ethereum/nft/skele/useCatacombs.js";
import getENV from "../../../../util/getENV.js";
import { useAccount } from "wagmi";
import useObituary from "../../../../hooks/ethereum/nft/skele/useObituary.js";
import { Link } from "react-router-dom";
import defined from "../../../../util/defined.js";
import equiv from "../../../../util/equiv.js";
import TxInputButton from "./elements/TxInputButton.js";
import TxButton from "./elements/TxButton.js";
import TxEditableText from "./elements/TxEditableText.js";
import LinkButton from "./elements/LinkButton.js";
import useLocalState from "../../../../hooks/useLocalState.js";
import { toTitleCase } from "../../../../util/StringUtils.js";

export default function Headshot({ address }) {
    //console.log('>>> Headshot')
    const { override, modules, promo: { profile: profilePromo } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    address = address ?? wallet;
    const isMine = wallet == address;
    const { obituary } = useObituary();
    const tag = obituary.getName(address);
    const tokenID = obituary.getToken(address);
    const lockedTime = obituary.getTimestamp(address);
    const bio = obituary.getProfileItem(address, 'bio')
    const tagline = obituary.getProfileItem(address, 'tagline')
    const twitter = obituary.getProfileItem(address, 'twitter')
    const instagram = obituary.getProfileItem(address, 'instagram')
    const discord = obituary.getProfileItem(address, 'discord')

    const { catacombs } = useCatacombs();
    const info = catacombs.getTokenInfo(tokenID);

    const [activeTokenID, setActiveTokenID] = useState();
    const closeOverlay = () => {
        setActiveTokenID(null);
    }
    const [name, setName] = useState('');
    const nameIsAvailable = name?.length > 0 && obituary.nameAvailable(name);
    const hasIdentity = obituary.hasIdentity(address);
    const enableSetNewName = (name?.length > 0 && nameIsAvailable) && defined(wallet, modules.obituary.contract.address, tokenID);
    const { status: nameStatus, error: nameError, write: setNewName } = obituary.setMyName(name).config({ enabled: enableSetNewName });
    const handleNameInput = (name) => {
        setName(name?.toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        //console.log(name);
    }


    const [newTagline, setNewTagline] = useState();
    const enableSetTagline = hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(tagline) ? !equiv(tagline, newTagline) : true) && newTagline?.length > 0
    const { status: taglineStatus, error: taglineError, write: setTagline } = obituary.setMyProfileItem('tagline', newTagline).config({ enabled: enableSetTagline });
    const handleTaglineInput = (tagline) => {
        setNewTagline(tagline.substr(0, 32));
    }
    const [newBio, setNewBio] = useState();
    const enableSetBio = hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(bio) ? !equiv(bio, newBio) : true) && newBio?.length > 0
    const { status: bioStatus, error: bioError, write: setBio } = obituary.setMyProfileItem('bio', newBio).config({ enabled: enableSetBio });
    const handleBioInput = (bio) => {
        setNewBio(bio);//?.substr(0, 32));// toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        //console.log(`${newBio}->${bio}`);
    }
    const [newTwitter, setNewTwitter] = useState(twitter);
    const enableSetTwitter = hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(twitter) ? !equiv(twitter, newTwitter) : true) && newTwitter?.length >= 5 && newTwitter?.length <= 16;
    const { status: twitterStatus, error: twitterError, write: setTwitter } = obituary.setMyProfileItem('twitter', newTwitter).config({ enabled: enableSetTwitter });
    const handleTwitterInput = (twitter) => {
        if (twitter !== '') setNewTwitter(!twitter ? twitter : twitter == '@' ? '@' : `@${twitter?.toLowerCase().replace('@', '').replace(/[^a-z0-9_]/, '').substr(0, 15)}`);
        //console.log(`${newBio}->${bio}`);
    }
    const [newDiscord, setNewDiscord] = useState(discord);
    const enableSetDiscord = hasIdentity && defined(wallet, modules.obituary.contract.address) && (defined(discord) ? !equiv(discord, newDiscord) : true) && newDiscord?.length > 0
    const { status: discordStatus, error: discordError, write: setDiscord } = obituary.setMyProfileItem('discord', newDiscord).config({ enabled: enableSetDiscord });
    const handleDiscordInput = (discord) => {
        setNewDiscord(discord?.substr(0, 32));// toLowerCase().replace(' ', '-').replace(/^[0-9-]/, '').replace(/[^a-z0-9-]/, '').replace(/-{2,}/, '-'));
        //console.log(`${newDiscord}->${discord}`);
    }

    const { status: eraseDiscordStatus, error: eraseDiscordError, write: eraseDiscord } = obituary.setMyProfileItem('discord', '');//.config({ enabled: enableSetDiscord });
    const { status: eraseTwitterStatus, error: eraseTwitterError, write: eraseTwitter } = obituary.setMyProfileItem('twitter', '');//.config({ enabled: enableSetTwitter });
    const { status: eraseTaglineStatus, error: eraseTaglineError, write: eraseTagline } = obituary.setMyProfileItem('tagline', '');//.config({ enabled: enableSetBio });
    const { status: eraseBioStatus, error: eraseBioError, write: eraseBio } = obituary.setMyProfileItem('bio', '');//.config({ enabled: enableSetBio });

    //DESTROY PROFILE 

    const {
        write: destroyProfile,
        status: destroyStatus,
        error: destroyError
    } = obituary.destroyMyIdentity();

    //EDIT MODE
    const [editing, setEditing] = useLocalState(`editing-profile-${address}`, isMine);
    const toggleEditing = () => {
        setEditing(!editing);
    }

    const tagTitle = tag?.split('-').map(toTitleCase).join(' ');
    //console.log(`tag:${tag},tokenID:${tokenID},bio:${bio},twitter:${twitter},discord:${discord}`);

    const editable = isMine && editing;
    return (<>
        {!!activeTokenID && <NFTOverlay closeFunction={closeOverlay} collection={catacombs} tokenID={tokenID} />}
        <div className="profile" style={{ position: 'relative', border: '1px solid rgba(3, 255, 255, .5)' }} >
            {isMine && <div className="close-overlay" style={{ fontSize: '1.25rem', position: 'absolute', zIndex: 'unset' }} onClick={toggleEditing}>{editing ? '🔓' : '🔒'}</div>}
            <div className="user">
                <TxEditableText
                    style={{ color: 'rgb(3, 255, 255)', justifyContent: 'space-around' }}
                    className="profile-id"
                    status={nameStatus}
                    label={`👤 Choose a ${tagTitle ? 'different' : ''} name`}
                    loading="👤 Updating your name…"
                    onInput={handleNameInput}
                    disabled={!editing}
                    placeholder={`Write a new name`}
                    value={name}
                    onSubmit={setNewName}
                    submitLabel="sign"
                    submitEnabled={nameIsAvailable}
                >{tagTitle}</TxEditableText>
                {modules.gridStore.contract.address && (editable || tagline) &&
                    <div className="mint-title" style={{ paddingTop: 0 }}>
                        <TxEditableText
                            status={taglineStatus}
                            erase={eraseTagline}
                            eraseStatus={eraseTaglineStatus}
                            disabled={!editing}
                            style={{ display: 'flex', justifyContent: 'space-around' }}
                            onInput={handleTaglineInput}
                            placeholder={tagline || `Write your new tagline`}
                            label={`👤 Add a tagline`}
                            value={newTagline}
                            loading="setting your tagline…"
                            onSubmit={setTagline}
                            submitLabel="sign"
                            submitEnabled={enableSetTagline}
                        >{toTitleCase(tagline)}</TxEditableText>
                    </div>
                }
                {defined(info?.direction) &&
                    <div className={`profile-details ${info.direction}`}>
                        <div className="profile-pic" >
                            <Thumbnail collection={catacombs} tokenID={tokenID} label={false} icon={false} onClick={setActiveTokenID} />
                        </div>


                        {modules.gridStore.contract.address && (editing || twitter || discord || bio) &&
                            <div className="profile-info" style={{ maxWidth: '20rem' }}>
                                <>{modules.gridStore.contract.address && (editable || bio) &&
                                    <TxEditableText
                                        status={bioStatus}
                                        erase={eraseBio}
                                        eraseStatus={eraseBioStatus}
                                        disabled={!editing}
                                        onInput={handleBioInput}
                                        placeholder={bio || `Write your new bio`}
                                        label={`👤 Add a bio`}
                                        value={newBio}
                                        loading="setting your bio…"
                                        onSubmit={setBio}
                                        submitLabel="sign"
                                        submitEnabled={enableSetBio}
                                    >{toTitleCase(bio)}</TxEditableText>
                                }{(editing || twitter) &&
                                    <TxEditableText
                                        field="Twitter"
                                        erase={eraseTwitter}
                                        eraseStatus={eraseTwitterStatus}
                                        status={twitterStatus}
                                        disabled={!editing}
                                        label={"👤 Add Twitter/X"}
                                        loading="👤 Setting Twitter…"
                                        onInput={handleTwitterInput}
                                        placeholder={twitter || 'Enter your Twitter'}
                                        value={newTwitter}
                                        onSubmit={setTwitter}
                                        submitLabel="sign"
                                        submitEnabled={enableSetTwitter}
                                    >{twitter && <a style={{ color: "unset" }} target="_blank" href={`https://x.com/${twitter?.substring(1)}`}>{twitter}</a>}</TxEditableText>
                                    }</>

                                <>{(editing || discord) &&
                                    <TxEditableText
                                        field="Discord"
                                        disabled={!editing}
                                        erase={eraseDiscord}
                                        eraseStatus={eraseDiscordStatus}
                                        status={discordStatus}
                                        label={"👤 Add Discord"}
                                        loading="👤 Setting your discord…"
                                        onInput={handleDiscordInput}
                                        placeholder={'Enter your discord'}
                                        value={newDiscord}
                                        onSubmit={setDiscord}
                                        submitLabel="sign"
                                        submitEnabled={enableSetDiscord}
                                    >{discord}</TxEditableText>
                                }</>
                            </div>
                        }
                    </div>
                }
                <div className="mint-info welcome"> Time of Death {new Date(lockedTime * 1000).toLocaleString()}</div>
                {editing && <>
                    <div style={{
                        color: 'rgb(3, 255, 255)'
                    }} className="modify-note" >to switch pfp, open the token you want from <Link className="info-link" to="/inventory">your collection</Link></div>
                    <div className="profile-actions">
                        <TxButton
                            status={destroyStatus}
                            onClick={destroyProfile}
                            loading="🚫 destroying your identity.. "
                            label="🚫 Destroy your Identity"
                        />
                    </div>
                </>}
            </div>
        </div >
    </>)
}