import { useEffect, useRef, useState } from "react";
import getRoman from "../../../../../util/getRoman.js";
import equiv from "../../../../../util/equiv.js";
import useObituary from "../../../../../hooks/ethereum/nft/skele/useObituary.js";
import { useAccount } from "wagmi";
import ImgLoader from "./ImgLoader.js";
import getENV from "../../../../../util/getENV.js";
import useSkeleKeys from "../../../../../hooks/ethereum/nft/skele/useSkeleKeys.js";
import useRemains from "../../../../../hooks/ethereum/nft/skele/useRemains.js";
const imgs = [];
export default function Thumbnail({ collection, className, tokenID, onClick, active, icon, label = true, status }) {
    //console.log('>>> Thumbnail', tokenID)
    const { modules } = getENV();
    const handleClick = (n) => {
        onClick?.(tokenID);
    }
    const { isConnected, address: wallet } = useAccount()
    const { obituary } = useObituary();
    let isPFP
    if (modules.obituary.contract.address) {
        const pfpToken = obituary.getToken(wallet);
        isPFP = isConnected && pfpToken == tokenID;
    }
    const { skeleKeys: keys } = useSkeleKeys();
    const { remains } = useRemains();
    const fragments = remains.fragmentsRemainingForToken(wallet, tokenID);
    const symbol = collection.symbolOf?.(tokenID) ?? collection.symbol();
    const meta = collection.getMetadata(tokenID);
    const isKey = equiv(symbol, keys.symbol());
    const isLocked = !isKey && collection.isLocked?.(tokenID);
    const idString = isKey ? getRoman(tokenID) : `#${tokenID}`;
    const KEY_IMG = '/images/SKELEKEY.gif';
    const TOKEN_IMG = '/images/spinner.gif';
    const ACTIVE_IMG = '/images/demo/dark/blank.gif';
    const image = meta?.image;
    const [imgSRC, setImgSRC] = useState(isKey ? KEY_IMG : TOKEN_IMG);
    const imageLoaded = (src) => {
        if (imgSRC !== src) setImgSRC(src)
    }
    const emoji = isPFP ? '👤'
        : isLocked ? '⚰️'
            : '';
    const title = isPFP ? 'This is your PFP'
        : isLocked ? 'Locked in the Catacombss'
            : '';

    return (//style={{ marginBottom: label ? 0 : '1rem' }}
        <div onClick={handleClick} className={`thumbnail ${className}`} >
            <ImgLoader
                src={image}
                onLoad={imageLoaded}
            />
            <div style={{
                position: 'relative', borderWidth: `${isPFP || isLocked ? '1px' : '0px'}`,
            }}>
                {icon && emoji &&
                    <div title={title} style={{ fontSize: '1rem', position: "absolute", bottom: '.25rem', right: '.25rem' }}>
                        {emoji}
                    </div>
                }
                {status && fragments > 0 &&
                    <div title={title} style={{ fontSize: '.75rem', background: 'rgba(0,0,0,0.5)', fontFamily: 'silkscreen', borderRadius: '0 1rem 1rem 0', padding: '.25rem .5rem', position: "absolute", top: 0, left: 0 }}>
                        ✨{fragments}
                    </div>
                }
                <img
                    src={active ? (isKey ? KEY_IMG : ACTIVE_IMG) : imgSRC}
                    loading="lazy"
                    alt=""
                    className={isKey ? 'key-image' : 'profile-picture'}
                />
            </div>
            {label && <h1 style={isPFP ? { color: 'rgb(3, 255, 255)' } : isLocked ? { color: 'var(--orange)' } : {}} className={`${isKey ? 'key-id' : 'id-number'} ${isLocked ? 'sealed-at' : ''}`}>{idString}</h1>}
        </div>
    )
}