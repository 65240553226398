import { useAccount, useEnsName } from "wagmi";
import getENV from "../../../util/getENV";
import { Link, useParams } from "react-router-dom";
import Header from "./modules/Header";
import { useState } from "react";
import useObituary from "../../../hooks/ethereum/nft/skele/useObituary";
import defined from "../../../util/defined";
import Headshot from "./modules/Headshot";
import useCatacombs from "../../../hooks/ethereum/nft/skele/useCatacombs";
import useSkelephunks from "../../../hooks/ethereum/nft/skele/useSkelephunks";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import usePageTracking from "../../../hooks/usePageTracking";
import TokenPicker from "./modules/elements/TokenPicker";

export default function Profile() {
    //console.log('>>> Profile')

    usePageTracking('Profile');

    const { override, modules } = getENV();
    const account = useAccount();
    let isConnected, accountWallet
    if (override) {
        isConnected = true;
        accountWallet = override
    } else {
        isConnected = account.isConnected;
        accountWallet = account.address;
    }
    const { name } = useParams();
    const { obituary } = useObituary();
    const { catacombs } = useCatacombs();
    const { skelephunks } = useSkelephunks();
    const wallet = defined(name) ? obituary.getAddress(name.toLowerCase()) : accountWallet;
    const isMine = wallet == accountWallet;
    const hasProfile = obituary.hasIdentity(wallet);
    const hasTokens = skelephunks.balanceOf(wallet) + catacombs.balanceOf(wallet) > 0;
    const { open } = useWeb3Modal();
    return (<div className="landing wf-section">
        <Header subtitle={isMine ? 'Your Identity' : 'The Community'} />
        {hasProfile ? <> <Headshot address={wallet} />
        </> : <><div> <Link onClick={() => { if (!isConnected) open(); }} to={!hasTokens ? '/mint' : '/inventory'} className="cta">{!isConnected || !hasTokens ? 'MINT' : 'SELECT'} A TOKEN</Link>
        </div><div className="modify-note">to set up your profile</div></>
        }
        <TokenPicker />
    </div >
    )
}

<div className="top">

</div>