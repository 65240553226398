import { useRef } from "react";

export default function RefInput({ min, max, type, style, value, onInput, placeholder }) {
    const ref = useRef();
    const handleInput = e => {
        onInput(e.target.value);
        ref.current.focus();
    }
    const clearInput = () => {
        if (value == '' || (type == 'number' && value == 0)) {
            onInput?.();
        }
        else {
            onInput?.('');
            ref.current.focus();
        }
    }
    return (<>
        <input
            ref={ref}
            type={type || "text"}
            value={value}
            min={min}
            max={max}
            style={style}
            onInput={handleInput}
            className="address-input input"
            placeholder={placeholder}>
        </input>
        <button
            className="cancel-button"
            onClick={clearInput}
        > x</button>
    </>)
}