import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./modules/Header";
import SearchBox from "./modules/elements/SearchBox";
import SKELE from "./modules/SKELE";
import usePageTracking from "../../../hooks/usePageTracking";

export default function Token() {
    //console.log('>>> Token')

    const { tokenID } = useParams();
    usePageTracking(`Token`);
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle="The Collection" />
        <div id="inventory" className="inventory wf-section">
            <div className="list">
                <SKELE tokenID={tokenID} />
                <SearchBox />
            </div>
            <div className="cta-info eth">reveal random new skelephunks</div>
            <div className="top">
                <Link to={'/'} className="cta">MINT MORE NOW</Link>
            </div>
        </div>

    </div>
    )
}
