import { useState } from "react";
import NFTOverlay from "../NFTOverlay.js";
import Thumbnail from "./Thumbnail.js";
import UnloadedTokens from "./UnloadedTokens.js";
import equiv from "../../../../../util/equiv.js";
import defined from "../../../../../util/defined.js";
import useEventTracking from "../../../../../hooks/useEventTracking.js";

export default function TokenGrid({ collection, tokenIDs, balance, loadMore, icons, statuses, labels }) {
    //console.log('>>> Grid')
    const { track } = useEventTracking();
    const [activeTokenID, setActiveTokenID] = useState();//'rgba(64, 32, 128,0.25)'
    const type = collection.symbol();

    const borderColor = type == 'MYSKELE' ? 'rgba(255, 170, 0, 0.25)'
        : type == 'SKEYS' ? 'rgba(255, 255, 0, 0.25)'
            : type == 'SKELE' ? 'rgba(3, 255, 0, 0.25)'
                : 'black';
    const closeOverlay = () => {
        setActiveTokenID(null);
    }
    const openOverlay = (id) => {
        setActiveTokenID(id)
        track({ event: 'token-overlay', category: 'token-view', label: id });
    }
    return (<>
        {!!activeTokenID && <NFTOverlay closeFunction={closeOverlay} collection={collection} tokenID={activeTokenID} />}
        {defined(tokenIDs) ? < div className={type == 'SKEYS' ? 'keys' : 'grid'} style={{ border: `2px solid ${borderColor}`, boxSizing: 'content-box' }} >
            {tokenIDs.map((tokenID, index) => <Thumbnail onClick={openOverlay} active={equiv(activeTokenID, tokenID)} key={`grid-${index}`} collection={collection} tokenID={tokenID} icon={icons} status={statuses} labels={labels} />)}
            {tokenIDs.length < balance && <UnloadedTokens clickHandler={loadMore} quantity={balance - tokenIDs?.length} />}
        </div >
            : equiv(balance, 0) ? ''
                : <img src="/images/spinner.gif" />
        }
    </>)
}