export default function COMIC() {
    //console.log('>>> PROMO')
    return (<>
        <div id="comic" className="my-skelephhunks-title">Skelephunks: The Comic</div>
        <div style={{
            border: '2px solid rgba(255, 255, 255, 0.25)'
        }} className="promotion" ><img src="/images/COMIC-COVER.png" loading="lazy" alt="" className="promo-image" />
            <div className="promo-content">
                <h1 className="promo-title">The Spirit of Phunkism</h1>
                <div className="promo-copy">An all-original look into the origins of the Skelephunks, in comic book form. <br />‍
                    <br />Join Skelly and bird as they set out on a journey to find adventure, only to discover something even more important along the way.<br />
                    ‍<br />story by <span className="free">Autopsyop</span><br />Art by <span className="free">Heffy</span><br />Produced with <span className="free">DPOMME</span><br />
                    <br />Special guest additions by <span className="free">&quot;Very&quot; William Dalosio</span>
                </div>
                <a href="THE-SPIRIT-OF-PHUNKISM.pdf" target="_blank" className="cta">read now</a>
            </div>
        </div >
    </>)
}