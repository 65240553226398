export default function ActionButton({ style, label, onClick, disabled, className }) {
    //console.log('>>> ActionButton')

    return (
        <div
            disabled={disabled}
            style={style}
            className={`action w-button catacombs ${className}`}
            onClick={onClick}
        >
            {label}
        </div>
    )
}