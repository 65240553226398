import defined from "./defined";

export default function getRedirect(mapping) {
    //console.log('>>> getRedirect')
    if (window.location.hostname == 'localhost') return
    const protocol = window.location.protocol
    //console.log(protocol);
    const domain = window.location.hostname.match(/([a-z0-9-]*?)\.[a-z]{2,3}$/)?.[1];
    //console.log(domain);
    const subdomain = window.location.hostname.match(/([a-z0-9-]*?)\.}/)?.[0];
    if (['test', 'stage'].indexOf(subdomain) > -1) return
    //console.log(subdomain);
    const route = mapping[subdomain];
    const path = window.location.pathname;

    if (defined(route)) {
        const url = `${protocol}//app.${domain}/${path}${route.indexOf('/') > -1 ? path : ''}`;
        window.location.assign(url);
    }
}