import { useState } from "react";
import TxInputButton from "./TxInputButton";

export default function TxEditableText({ children, className, erase, style, label, status, eraseStatus, loading, disabled, placeholder, value, onInput, fieldPlaceholder, field, onFieldInput, onSubmit, submitEnabled, submitLabel }) {
    //console.log('>>> TxEditableText')

    const [input, setInput] = useState(false);
    const [erasing, setErasing] = useState(false);
    const startInput = () => {
        setInput(true);
    }
    const doErase = () => {
        setErasing(true);
        erase();
    }
    const active = ['disabled', 'idle', 'error'].indexOf(status) == - 1;
    const eraseActive = ['disabled', 'idle', 'error'].indexOf(eraseStatus) == - 1

    return (
        <div
            className={className}
            style={style}
        >
            {(!input || disabled || erasing) && (erasing ? !eraseActive : !active) && (!!children) ?
                <div style={{
                    display: 'flex',
                    columnGap: '.5rem',
                    margin: 0,
                    pading: 0
                }}>
                    {field && <div className="free">{field}:</div>}
                    <div>{children}</div>
                    <div style={{ columnGap: '.5rem', display: 'flex', fontSize: '1rem' }}>
                        {!disabled && <div onClick={startInput} >✏️</div>}
                        {!disabled && erase && <div onClick={doErase} >❌</div>}
                    </div>
                </div>
                : <TxInputButton
                    input={input}
                    setInput={setInput}
                    label={label}
                    status={erasing ? eraseStatus : status}
                    loading={loading}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onInput={onInput}
                    fieldPlaceholder={fieldPlaceholder}
                    field={field}
                    onFieldInput={onFieldInput}
                    onSubmit={onSubmit}
                    submitEnabled={submitEnabled}
                    submitLabel={submitLabel}
                />
            }
        </div>
    )
}