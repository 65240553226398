import {
    useAccount,
} from "wagmi";

import getENV from '../../../util/getENV.js';
import UnclaimedKeys from './modules/elements/UnclaimedKeys.js';
import Collection from './modules/Collection.js';
import useSkelephunks from '../../../hooks/ethereum/nft/skele/useSkelephunks.js';
import scrollToAnchor from '../../../util/scrollToAnchor.js';
import defined from '../../../util/defined.js';
import { Link } from 'react-router-dom';
import ConnectButton from './modules/elements/ConnectButton.js';
import COMIC from './modules/COMIC.js';
import useSkeleKeys from '../../../hooks/ethereum/nft/skele/useSkeleKeys.js';
import useCatacombs from '../../../hooks/ethereum/nft/skele/useCatacombs.js';
import useObituary from '../../../hooks/ethereum/nft/skele/useObituary.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
import InfoPop from './modules/elements/InfoPop.js';
import Header from "./modules/Header.js";
export default function Inventory() {
    //console.log('>>> Inventory')

    usePageTracking('Inventory');
    const BATCH_SIZE = 21;
    const { override, modules, promo: { profile, rewards } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    //balances
    //tokens
    const { skelephunks } = useSkelephunks();
    const tokenBalance = skelephunks.balanceOf(wallet);
    const hasTokens = tokenBalance > 0;
    //keys
    const { skeleKeys: keys } = useSkeleKeys();
    const unclaimedKeys = keys.unclaimedOf(wallet)
    const { tokenIDs: keysList, balance: keysBalance } = keys.tokensOfOwner(wallet);
    const numKeys = keysList?.length || 0;
    const claimUntil = unclaimedKeys > 0 ? keys.getClaimDeadline(wallet) : 0;
    //locked
    const { catacombs } = useCatacombs()
    let lockedBalance;
    if (modules.catacombs.contract.address) {
        lockedBalance = catacombs.balanceOf(wallet);
    }

    const { obituary } = useObituary();
    let hasIdentity, hasProfile, profileTag, profileToken, doa, deadForDays;
    if (modules.obituary.contract.address) {
        hasIdentity = obituary.hasIdentity(wallet);
        hasProfile = isConnected && hasIdentity;
        profileTag = obituary.getName(wallet);
        profileToken = obituary.getToken(wallet);
        doa = obituary.getTimestamp(wallet);
        deadForDays = Math.floor(doa + Date.now() / 1000) / (24 * 60 * 60);
    }
    const hasLockedTokens = lockedBalance > 0;
    const skeleSize = tokenBalance + lockedBalance;
    // const inventorySize = skeleSize + keysBalance;
    // const hasInventory = inventorySize > 0;
    // const loadedSize = tokens?.length || 0 + lockedList?.length || 0 + keysList?.length || 0;
    // const loaded = hasInventory && inventorySize == loadedSize;


    return (
        <div className="landing wf-section">

            <Header subtitle="Your Collection" />
            {((defined(lockedBalance) || defined(tokenBalance)) && defined(profile) && !hasIdentity || defined(lockedBalance) || deadForDays < 7) && <>
                {!hasIdentity ?
                    < div style={{
                        fontFamily: 'silkscreen',
                        background: 'black',
                        padding: '1rem',
                        borderRadius: '1rem',
                        color: 'var(--cyan)'
                    }}>
                        {`${!hasIdentity ? profile.icon : rewards.icon} ${!hasIdentity ? profile.message : rewards.message}`}
                    </div>
                    : <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                    </div>}
            </>}

            <div style={{ marginBottom: '1rem' }} className="modify-note">come back at any time to update the gender or direction of your Skelephunk{skeleSize > 1 ? 's' : ''}</div>
            <div id="inventory" className="inventory">
                {isConnected ? <>
                    {unclaimedKeys + numKeys > 0 && <>
                        <div className="div-block-18">
                            <h3 className="skeleton-keys">{keysBalance} Skeleton Key{keysBalance > 1 ? 's' : ''}</h3>
                            <InfoPop icon="?" title="Congratulations!">
                                <div>A Skeleton Key is rare skelephunks treasure.</div>
                                {!!keysBalance && <div>Click a key to learn how to use it.</div>}
                                {!!unclaimedKeys && <>
                                    <div className="claim-note">
                                        You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}.
                                    </div>
                                </>}
                            </InfoPop>
                        </div>
                        <Collection collection={keys} wallet={wallet} mode={'grid'} labels={true} />
                        {//unclaimed keys
                            !!unclaimedKeys && <UnclaimedKeys quantity={unclaimedKeys} />
                        }
                        {!!unclaimedKeys && <div className="unclaimed-info">
                            <div className="claim">
                                You have {unclaimedKeys} unclaimed key{unclaimedKeys > 1 && 's'}. Click the <span className="unclaimed">+{unclaimedKeys}</span> to claim.
                            </div>
                            <div className="claim-date">
                                (your key{unclaimedKeys > 1 && 's'} can be claimed until {new Date(claimUntil).toLocaleDateString()})
                            </div>
                        </div>}
                    </>}
                    {defined(lockedBalance) || defined(tokenBalance) ? <>
                        {hasLockedTokens && <>
                            <div className="div-block-18">
                                <h3 className="skeleton-keys">
                                    {lockedBalance} in the Catacombs
                                </h3>
                                <InfoPop icon="?" title="Locked Skelephunks">
                                    <div>Skelephunks in the Catacombs are "Locked".</div>
                                    <div>The longer a token stays locked, the more rewards it will yield.</div>
                                </InfoPop>
                            </div>
                            <Collection wallet={wallet} collection={catacombs} toggle={false} statuses={true} /></>}
                        {(!hasLockedTokens || hasTokens) &&
                            <h3 className="skeleton-keys">
                                {!hasTokens ? 'No' : tokenBalance > 7 ? tokenBalance : ''} {lockedBalance > 0 ? 'More ' : ''}Skelephunk{tokenBalance !== 1 && 's'}
                            </h3>
                        }
                        {hasTokens && <Collection wallet={wallet} collection={skelephunks} toggle={true} />}
                        {tokenBalance + lockedBalance == 0 && <div className="top">
                            <Link to={'/mint'} className="cta">MINT YOURS NOW</Link>
                        </div>}
                    </> : <div id="contentSpinner" className="spinner"><img src="/images/spinner.gif" /></div>}

                    {!!(tokenBalance + lockedBalance + numKeys) && <>

                        <div className="modify-note">exclusive access for holders:</div>
                        <COMIC />
                        <div className="top">
                            <div onClick={() => { scrollToAnchor('inventory') }} className="cta">back to top</div>
                        </div></>}
                </> : <><div className="mint-info free">Connect your wallet to view your Skelephunks</div><div><ConnectButton /></div></>}
            </div>
        </div >
    )
}