import { useState } from 'react';
import { useAccount } from "wagmi";
import getENV from "../../../../util/getENV.js";
import GAD from '../../../../util/GAD.js';
import PFP from './elements/PFP.js';
import defined from '../../../../util/defined.js';
import useMerkle from '../../../../hooks/useMerkle.js';
import equiv from '../../../../util/equiv.js';
import useSkeleDrop from '../../../../hooks/ethereum/nft/skele/useSkeleDrop.js';

export default function CLAIM({ }) {
    //console.log('>>> CLAIM')
    const { override, status, modules, etherscan } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const [genderToMint, setGenderToMint] = useState('male');
    const [directionToMint, setDirectionToMint] = useState('phunk');
    const [claiming, setClaiming] = useState();
    const [numToClaim, setNumToClaim] = useState(1);
    const genderDirection = GAD.toNum(genderToMint, directionToMint);
    const { drops: { proof } } = useMerkle(wallet)


    const { drop } = useSkeleDrop();
    const numDrops = drop.claimableDropsForWallet(wallet, proof)

    const {
        write: claimDrop,
        status: claimStatus,
        error: claimError,
    } = drop.claim(numToClaim || numDrops, genderDirection, proof).config({
        gas: 300000 + 100000 * numToClaim,
        onComplete: window.location.reload
    })
    const mintPending = claimStatus == 'loading';
    const startClaiming = () => {
        setClaiming(true);

    }
    const quantityOptionsJSX = [];
    for (let i = 1; i < numDrops; i++) {
        quantityOptionsJSX.push(<option key={i} value={i}>{i}</option>);
    }
    quantityOptionsJSX.push(<option key='max' value="max">MAX</option>);
    function onQuantityChange(e) {
        const val = e.target.value;
        const value = equiv(val, 'max') ? numDrops : val;
        setNumToClaim(value);
    }
    const maxClaiming = equiv(numToClaim, numDrops);
    const selectedQuantityOption = maxClaiming || !defined(numToClaim) ? 'max' : numToClaim;//for updating the drop-down
    return (status.claims ?
        <div id={`claim`} className="nft">
            <h1 className="key-name">
                CLAIM {numDrops > 1 ? `UP TO ${numDrops} ` : 'YOUR '}FREE MINT{numDrops > 1 ? 'S' : ''}
            </h1>
            <div className="token">
                <div className="jpeg">
                    {!mintPending ? <PFP direction={directionToMint} setDirection={setDirectionToMint} gender={genderToMint} setGender={setGenderToMint} />
                        : <div id="pfpSpinner" className=""><img src="/images/spinner.gif" /></div>}
                    {claimStatus == 'loading' ?
                        <button
                            disabled={claimStatus == 'loading' || genderToMint == null || directionToMint == null}
                            className="cta w-button update-token"
                            onClick={claiming ? claimDrop : startClaiming}
                        >
                            {(claimStatus == 'error') ? 'TRY AGAIN'

                                : (claimStatus == 'loading') ? 'MINTING…'
                                    : 'CLAIM DROP'}
                        </button> :
                        <div className="form"
                            id="wf-form-Mint-Form"
                            name="wf-form-Mint-Form">
                            {numDrops > 1 &&
                                <select
                                    disabled={mintPending}
                                    id="Quantity"
                                    name="Quantity"
                                    data-name="Quantity"
                                    required=""
                                    className="select-field w-select"
                                    onChange={onQuantityChange}
                                    value={selectedQuantityOption}
                                >
                                    {quantityOptionsJSX}
                                </select>}
                            <button
                                onClick={claimDrop}
                                className="cta"
                                disabled={mintPending}>
                                CLAIM
                            </button>
                        </div>}
                    <div className="mint-info">

                        <div>select a gender and direction above</div>
                        <div className="error">you can always update it later</div>
                    </div>
                </div>

                <div style={{ maxWidth: '20rem' }} className="metadata"><div className="information">
                    <div className="token-info">
                        <p className="free">You have been dropped {numDrops > 1 ? numDrops : 'a'} free mint{numDrops > 1 ? 's' : ''} from the Skelephunks Crypt</p>
                        <p>Choose a Gender and Direction, amount you want, and click CLAIM to mint your free token{numToClaim > 1 ? 's' : ''}</p>
                        <p className="welcome"><span className="free">claim now!</span> offer only valid while supplies last</p>
                    </div>
                    <div className="tos-text">
                        <div>by clicking the claim button you agree to our</div>
                        <div >
                            <a className="eth" target="_blank" rel="noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vSUtHPQWr7fYpqalEVZ09xquZfygXqSb3MaxQ-qoKhRwRB8pgqvdP_9C-mzxevNDpotc3Wf-6TIX5a0/pub#h.u9z5qpdy0a2i'>terms of service</a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div >
        : <div className="key-name">CLAIMS ARE PAUSED FOR A TECHNICAL ISSUE</div>);
}
