import { useAccount } from "wagmi";
import useSkeleTokens from "../../../../../hooks/ethereum/nft/skele/useSkeleTokens"
import Thumbnail from "./Thumbnail";
import { useState } from "react";
import defined from "../../../../../util/defined";

const PAGE_SIZE = 48;

export default function TokenPicker({ title }) {
    const { address } = useAccount();
    const { skeleTokens } = useSkeleTokens();
    const [pageStart, setPageStart] = useState(0);
    const { balance, tokenIDs } = skeleTokens.tokensOfOwner(address, PAGE_SIZE, pageStart);
    title = title || 'SELECT A SKELE';
    const nextPage = () => {
        const start = Math.min(pageStart + PAGE_SIZE, balance - PAGE_SIZE) || 0;
        console.log('nextPage:', start)
        setPageStart(start);
    }
    const prevPage = () => {
        const start = Math.max(pageStart - PAGE_SIZE, 0);
        console.log('prevPage:', start)
        setPageStart(start)
    }
    return (
        <div className="tokenpicker">
            <div className="picker">
                <h1 className="panel-title" style={{ display: 'flex', justifyContent: 'center' }}><div onClick={prevPage}>&lt; </div>{title}<div onClick={nextPage}> &gt;</div></h1>
                <div className="pickergrid">

                    {tokenIDs?.map((i) => {
                        return (
                            <div className="pickerchip" key={`chip-${i}`}>
                                <Thumbnail
                                    className="profile-picture"
                                    tokenID={i}
                                    collection={skeleTokens}
                                    label={false}
                                    icon={false}
                                />
                            </div>)
                    })}
                </div>
            </div>
        </div>
    )
}