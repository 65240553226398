import SKEY from "./SKEY.js";
import SKELE from "./SKELE.js";
import MYSKELE from "./MYSKELE.js";
import useCatacombs from "../../../../hooks/ethereum/nft/skele/useCatacombs.js";
import getENV from "../../../../util/getENV.js";
import useSkelephunks from "../../../../hooks/ethereum/nft/skele/useSkelephunks.js";
import useEventTracking from "../../../../hooks/useEventTracking.js";

export default function NFTOverlay({ context, collection: coll, tokenID, closeFunction }) {
    //console.log('>>> NFTOverlay')
    const { modules } = getENV();
    const { catacombs } = useCatacombs();
    const { skelephunks } = useSkelephunks();
    let collection = coll;
    if (modules.catacombs.contract.address) {
        collection = (skelephunks.ownerOf(tokenID) == modules.catacombs.contract.address) ? catacombs
            : (coll?.symbol() == 'MYSKELE') ? skelephunks
                : coll;
    }
    const type = collection?.symbol();
    const borderColor = type == 'MYSKELE' ? 'rgba(255, 170, 0,0.25)'
        : type == 'SKEYS' ? 'rgba(255, 255, 0,0.25)'
            : type == 'SKELE' ? 'rgba(3, 255, 0,0.25)'
                : 'black';
    return (
        <div className="nft-modal">
            <div className="item" style={{ border: `2px solid ${borderColor}`, boxSizing: 'content-box', boxShadow: '0px 38px 59px 0px rgba(0,0,0,0.75)' }}>
                {collection.symbol() == 'SKEYS' && <SKEY tokenID={tokenID} context={context} closeOverlay={closeFunction} />}
                {type == 'SKELE' && <SKELE context={context} tokenID={tokenID} closeFunction={closeFunction} />}
                {type == 'MYSKELE' && <MYSKELE context={context} tokenID={tokenID} closeFunction={closeFunction} />}
                <div className="close-overlay" onClick={closeFunction}>×</div>
            </div>
            <div className="dark-overlay" onClick={null}></div>
        </div >
    )
}