import defined from "../../../../util/defined";
import getENV from "../../../../util/getENV";
import useCatacombs from "./useCatacombs";
import useSkelephunks from "./useSkelephunks";

export default function useSkeleTokens() {
    const { modules: { catacombs: { contract: { address: catacombsAddress } } } } = getENV();
    const { skelephunks } = useSkelephunks(undefined);
    const { catacombs } = useCatacombs();
    if (!catacombsAddress) return { skeleTokens: skelephunks }
    const isLocked = (id) => {
        const owner = skelephunks.ownerOf(id)
        return owner == catacombsAddress
    }
    const collectionOf = (id) => {
        return isLocked(id) ? catacombs : skelephunks;
    }
    const ownerOf = (id) => {
        return isLocked(id) ? catacombs.ownerOf(id) : skelephunks.ownerOf(id);
    }
    const tokensOfOwner = (owner, loadAmt, loadStart) => {
        const balance = balanceOf(owner);
        let tokenIDs, showMy;
        const { myBalance, tokenIDs: myskeleIDs } = catacombs.tokensOfOwner(owner, loadAmt, loadStart);
        if (defined(myBalance)) {
            if (loadStart < myBalance) {
                showMy = true
                loadAmt -= (myBalance - loadStart);
            }
            const { tokenIDs: skeleIDs } = skelephunks.tokensOfOwner(owner, loadAmt, loadStart);
            tokenIDs = showMy ? myskeleIDs?.concat(skeleIDs) : skeleIDs;
        }
        return { balance, tokenIDs };
    }
    const balanceOf = (owner) => {
        const skeleBalance = skelephunks.balanceOf(owner);
        const myskeleBalance = catacombs.balanceOf(owner);
        return defined(skeleBalance, myskeleBalance) ? skeleBalance + myskeleBalance : undefined
    }

    return { skelephunks, catacombs, skeleTokens: { ...skelephunks, isLocked, ownerOf, collectionOf, tokensOfOwner, balanceOf } }
}