import {
    useState
} from 'react';

import {
    useAccount,
} from "wagmi";

import getENV from '../../../util/getENV.js';
import UnclaimedKeys from './modules/elements/UnclaimedKeys.js';
import Collection from './modules/Collection.js';
import useSkelephunks from '../../../hooks/ethereum/nft/skele/useSkelephunks.js';
import scrollToAnchor from '../../../util/scrollToAnchor.js';
import Header from './modules/Header.js';
import defined from '../../../util/defined.js';
import { Link } from 'react-router-dom';
import ConnectButton from './modules/elements/ConnectButton.js';
import COMIC from './modules/COMIC.js';
import useSkeleKeys from '../../../hooks/ethereum/nft/skele/useSkeleKeys.js';
import useCatacombs from '../../../hooks/ethereum/nft/skele/useCatacombs.js';
import useObituary from '../../../hooks/ethereum/nft/skele/useObituary.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
import InfoPop from './modules/elements/InfoPop.js';
import ActionButton from './modules/elements/ActionButton.js';
import LinkButton from './modules/elements/LinkButton.js';
import Member from './modules/Member.js';
export default function Community() {
    //console.log('>>> Community')

    usePageTracking('Community');
    const BATCH_SIZE = 21;
    const { override, modules, promo: { profile } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }    //balances
    //tokens
    const { skelephunks } = useSkelephunks();
    const tokenBalance = skelephunks.balanceOf(wallet);
    const hasTokens = tokenBalance > 0;
    const { obituary } = useObituary();
    const registry = obituary.getRegistry()
    const hasIdentity = obituary.hasIdentity(wallet);
    const hasProfile = isConnected && hasIdentity;
    const profileTag = obituary.getName(wallet);
    const profileToken = obituary.getToken(wallet);


    return (
        <div className="landing wf-section">
            <Header subtitle="The Community" />
            {defined(registry) && <>
                <div style={{ display: 'flex', justifyContent: 'center' }}><h3 className="skeleton-keys">{registry.length == 0 ? 'No' : registry.length} Active Profile{registry.length == 1 ? '' : 's'}</h3>
                    {!hasProfile && <InfoPop icon="?" title="Profiles are Live!">
                        <div>To create a Skelefrens profile {isConnected ? 'connect your wallet, ' : ''}{hasTokens ? 'mint some tokens, ' : ''} think of a name you love, then open a token in your inventory and make it your profile picture 👤</div>
                    </InfoPop>}</div>
                {!!registry.length &&
                    <div id="members" style={{ margin: '0 auto' }} className="members">
                        {[...new Set(registry)].map((address, index) => <Member key={`member-${address}`} address={address} />)}
                    </div >}
            </>}
        </div>
    )
}