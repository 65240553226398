export default function InfoPop({ icon, title, children }) {
    return (
        <div className="keys-info">
            <div>{icon}</div>
            <div className="key-info-text">
                <div className="key-bubble">
                    <div className="congratulations">{title}</div>
                    {children}
                </div>
            </div>
        </div>
    )
}