import TxButton from "./TxButton";
import defined from "../../../../../util/defined";
import RefInput from "./RefInput";
import { useState } from "react";
import FieldInput from "./FieldInput";

export default function TxInputButton({ type, style, input: _input, setInput: _setInput, label, status, loading, disabled, placeholder, value, onInput, fieldPlaceholder, field, onFieldInput, onSubmit, submitEnabled, submitLabel }) {
    //console.log('>>> TxInputButton')
    const [input, setInput] = useState();
    _input = _input ?? input;
    _setInput = _setInput ?? setInput;
    const startInput = () => _setInput(true);
    const handleInput = (input) => {
        if (!defined(input)) _setInput(false);
        onInput?.(input);
    }
    const handleClick = () => {
        onSubmit();
        _setInput(false);
    }
    return (<>
        {!_input ?
            <TxButton
                style={style}
                disabled={disabled}
                onClick={startInput}
                status={status}
                label={label}
                loading={loading}
            />
            :
            <>
                <FieldInput
                    type={type}
                    field={field}
                    onFieldInput={onFieldInput}
                    fieldPlaceholder={fieldPlaceholder}
                    value={value}
                    onInput={handleInput}
                    placeholder={placeholder}
                    submitLabel={submitLabel}
                    onSubmit={handleClick}
                    submitEnabled={submitEnabled}
                />
            </>
        }
    </>)
}