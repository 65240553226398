import getENV from '../../../util/getENV.js';
import { Link } from 'react-router-dom';
import Header from './modules/Header.js';
import useSkelephunks from '../../../hooks/ethereum/nft/skele/useSkelephunks.js';
import Collection from './modules/Collection.js';
import defined from '../../../util/defined.js';
import equiv from "../../../util/equiv.js";
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import usePageTracking from '../../../hooks/usePageTracking.js';
export default function Graveyard() {
    //console.log('>>> Graveyard')

    usePageTracking('Graveyard');

    const { override, modules } = getENV()
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const { open } = useWeb3Modal();
    const { skelephunks } = useSkelephunks();
    const gBalance = skelephunks.balanceOf(modules.graveyard.contract.address);
    const balance = skelephunks.balanceOf(wallet);
    // const allHere = equiv(tokenIDs?.length, gBalance);
    const showCollection = gBalance > 0;// && allHere
    //
    return (<div className="landing" data-w-id="31a62934-7dee-bf92-5793-1bf374967db7">
        <Header subtitle="The Graveyard" />
        <div id="inventory" className="inventory wf-section">
            {showCollection ?
                <Collection wallet={modules.graveyard.contract.address} collection={skelephunks} />
                : <>
                    <img src="/images/spinner.gif" />
                    {defined(gBalance) && <div className="token-name yours">The Graveyard is {equiv(gBalance, 0) ? 'Empty' : 'Loading…'}</div>}
                </>}
            <div className="cta-info eth">reveal random new skelephunks</div>
            <div className="top">
                <Link onClick={() => { if (!isConnected) open(); }} to={'/mint'} className="cta">MINT {!isConnected || balance == 0 ? 'YOURS' : 'MORE'} NOW</Link>
            </div>
        </div>
    </div>)
}