import { useRef, useState } from "react";

let timer;
let refresh = 1;
/**
 * Custom React hook for managing a redraw mechanism.
 * @returns {{ update: number, redraw: Function }} An object containing the update value and the redraw function.
 */
export default function useRedraw() {
    //console.log('>>> useRedraw')
    // const timer = useRef();
    const [, forceUpdate] = useState(1);
    /**
     * Updates the internal state and triggers a redraw.
     * @private
     */
    const update = () => {
        //console.log(` updating ${refresh}`)
        forceUpdate(refresh++);//trigger a redraw
        //console.log(` updated ${refresh}`)
    }
    /**
     * Flags the app for redraw, optionally forcing an immediate redraw.
     * @param {boolean} [force=false] Whether to force an immediate redraw.
     */
    const redraw = (force) => {
        clearTimeout(timer);//reset bundling timer on every call to redraw
        if (true) {
            update();//do not wait to bundle more requests, just update now (important for UI update before opening wallet)
        } else {
            //console.log('batching')
            timer = setTimeout(update, 300);//0.3s rolling delay to bundle multiple calls into a single update unless forced sooner
        }
    }
    return {

        /**
         * Function to trigger a redraw.
         * @type {Function}
         */
        redraw
    }
}