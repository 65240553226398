import { useRef } from "react";
import defined from "../../../../../util/defined";
import RefInput from "./RefInput";

export default function FieldInput({ min, max, type, style, placeholder, value, onInput, fieldPlaceholder, field, onFieldInput, submitEnabled, submitLabel, onSubmit }) {
    const colon = /\w/.test(field) ? ':' : '';
    return (
        <div
            className="input"
            style={style}  
        >
            {defined(field) && <>
                {defined(onFieldInput) ?
                    <RefInput
                        style={style}
                        value={field}
                        onInput={onFieldInput}
                        placeholder={fieldPlaceholder} />
                    :
                    <div
                        style={style} className="send-button" >
                        {field}{colon}
                    </div>
                }
            </>}
            <RefInput
                type={type}
                style={style}
                value={value}
                min={min}
                max={max}
                onInput={onInput}
                placeholder={placeholder} />
            {submitEnabled && <button onClick={onSubmit} className="send-button">{submitLabel}</button>}
        </div>)
}