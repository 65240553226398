import { Link, useLocation } from "react-router-dom";
import { useAccount, useBalance, useEnsAddress, useEnsName, useEstimateFeesPerGas, useGasPrice } from "wagmi";
import getENV from "../../../../util/getENV";
import useMerkle from '../../../../hooks/useMerkle';
import SearchBox from "./elements/SearchBox";
import defined from "../../../../util/defined";
import { ethers } from "ethers";
import { useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ConnectButton from "./elements/ConnectButton";
import equiv from "../../../../util/equiv";
import useSkelephunks from "../../../../hooks/ethereum/nft/skele/useSkelephunks";
import useSkeleKeys from "../../../../hooks/ethereum/nft/skele/useSkeleKeys";
import useCatacombs from "../../../../hooks/ethereum/nft/skele/useCatacombs";
import useObituary from "../../../../hooks/ethereum/nft/skele/useObituary";
import useSkeleDrop from "../../../../hooks/ethereum/nft/skele/useSkeleDrop";
import { mainnet } from "viem/chains";
import useLocalState from "../../../../hooks/useLocalState";
import Thumbnail from "./elements/Thumbnail";
import useSkeleTokens from "../../../../hooks/ethereum/nft/skele/useSkeleTokens";
import LinkButton from "./elements/LinkButton";
import useRemains from "../../../../hooks/ethereum/nft/skele/useRemains";

export default function Header({ subtitle }) {
    //console.log('>>> Header')
    const { override, testnet, config: { wagmi: wagmiConfig }, modules, promo: { profile, drop: dropPromo } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const { skeleTokens } = useSkeleTokens();
    const { drop } = useSkeleDrop();
    const { skeleKeys: keys } = useSkeleKeys();
    const { remains } = useRemains();
    const gasPrice = useEstimateFeesPerGas({
        config: wagmiConfig,
        formatUnits: 'gwei',
    })?.data?.formatted?.maxFeePerGas;
    const { data: balanceData, status: balanceStatus } = useBalance({ config: wagmiConfig, address: wallet });
    const walletBalance = defined(balanceData) ? Number(balanceData?.formatted).toPrecision(4) : "";
    const { data: ensData, status: ensStatus, error: ensError } = useEnsName({ address: wallet, chainId: 1 });

    const { obituary } = useObituary();
    let hasProfile, profileTag, profileToken, tokenURI;
    if (modules.obituary.contract.address) {
        profileTag = obituary.getName(wallet);
        hasProfile = obituary.hasIdentity(wallet);
        profileToken = hasProfile ? obituary.getToken(wallet) : null;
    }
    //console.log`hasProfile: ${hasProfile}, profileTag: ${profileTag}, profileToken: ${profileToken}`);
    const displayName = hasProfile ? profileTag : ensData || null;
    const numMinted = skeleTokens.numMinted(wallet);
    const numTokens = skeleTokens.balanceOf(wallet);

    const { catacombs } = useCatacombs();
    let numLocked = 0;
    if (modules.catacombs.contract.address) {
        numLocked = catacombs.balanceOf(wallet);
        tokenURI = profileToken ? catacombs.getMetadata(profileToken)?.image : '';
    }

    const numKeys = keys.balanceOf(wallet);
    const mintStatus = skeleTokens.mintStatus();
    const { drops: { proof } } = useMerkle(wallet);
    const numDrops = drop.claimableDropsForWallet(wallet, proof);
    const route = useLocation().pathname;
    const maxMinted = defined(numMinted, mintStatus) && mintStatus < 4 && numMinted > 12;
    const hasInventory = numTokens + numKeys > 0;
    const returnVisitor = hasInventory || numMinted > 0;

    // const mintPhases = ['pre', 'claim', 'list', 'pub', 'zoo', 'post'];
    const minted = defined(numMinted) && numMinted > 0;
    const { open } = useWeb3Modal();
    const ensureConnection = () => {
        if (!isConnected) open();
    }
    const [navShown, setNavShown] = useLocalState('nav-shown');
    const toggleNav = () => {
        setNavShown(!navShown);
    }
    const profileTimestamp = obituary.getTimestamp(wallet);

    const numFragments = remains.fragmentsRemaining(wallet);
    const numBones = remains.bonesRemaining(wallet);

    return (<>
        <div className="title">

            <div style={{ transition: 'none' }} className={`hud welcome ${navShown ? 'floating' : ''}`}>
                <div onClick={toggleNav} className={`shownav navcontrol`}>
                    <div className="text-block-15">{navShown ? 'HIDE' : 'FULL'} NAVIGATION</div>
                </div>
                <div className="profile-identity" ><div className="id-lockup">
                    {hasProfile &&
                        <Link style={{ color: 'unset' }} to="/profile">
                            <div className="avatar">
                                <Thumbnail collection={skeleTokens} tokenID={profileToken} icon={false} status={false} label={false} />
                            </div>
                        </Link>
                    }
                    <div className="salutation" >
                        <div>welcome {returnVisitor ? 'back ' : ''}</div>
                        <div>{displayName ? <span className="identity">{displayName}</span> : 'Anon'} </div>
                    </div>
                </div>
                    {route !== '/profile' && isConnected && hasProfile && navShown &&//preload Tokens onClick={() => skeleTokens.tokensOfOwner(wallet)}
                        <div className={navShown ? '' : 'hide-small'}>
                            < LinkButton
                                to='/profile'
                                label={`${profile.icon} ${profile.button}`} />
                        </div>
                    }
                </div>
                <div >
                    <div>
                        {route !== '/mint' &&
                            <div className={`navlink yours ${navShown ? '' : 'hide-small'}`}>
                                <Link style={{ color: 'unset' }} onClick={ensureConnection} to="/mint">🔥 Mint Skelephunks</Link>
                            </div>
                        }
                        {route !== '/inventory' && isConnected && hasInventory &&//preload Tokens onClick={() => skeleTokens.tokensOfOwner(wallet)}
                            <div className={`navlink yours ${navShown ? '' : 'hide-small'}`}>
                                <Link style={{ color: 'unset' }} to="/inventory">💀 your collection</Link>
                            </div>
                        }
                        {route !== '/graveyard' &&
                            <div className={`navlink yours ${navShown ? '' : 'hide-small'}`}>
                                <Link style={{ color: 'unset' }} to="/graveyard">🪦 Visit Graveyard</Link>
                            </div>
                        }
                        {route !== '/catacombs' && navShown &&
                            <div className={`navlink yours ${navShown ? '' : 'hide-small'}`}>
                                <Link style={{ color: 'unset' }} to="/catacombs">⚰️ Browse Catacombs</Link>
                            </div>
                        }
                        {route !== '/community' && navShown &&
                            <div className={`navlink yours`}>
                                <Link style={{ color: 'unset' }} to="/community">👥 Find Frens</Link>
                            </div>
                        }
                        {route !== '/ossuary' && navShown && numBones + numFragments > 0 &&
                            <div className={`navlink yours`}>
                                <Link style={{ color: 'unset' }} to="/ossuary">⚖️ Manage Rewards</Link>
                            </div>
                        }
                        {route !== '/drop' && (!isConnected || defined(numDrops)) && (numDrops > 0 || defined(dropPromo)) &&
                            <div className="navlink free">
                                <Link className={`free navlink`} to="/drop">💧 {defined(dropPromo) && (!isConnected || (defined(numDrops) && numDrops == 0)) ? dropPromo.nav : `You Have ${numDrops > 1 ? numDrops : 'a'} drop${numDrops > 1 ? 's' : ''}`}</Link>
                            </div>
                        }
                    </div>
                    {route != '/drop' && navShown && <SearchBox hidden={false} />}
                </div>
            </div>
            <div className="banner">
                <Link className="" to="/">
                    <img
                        src={`/images/SKELEPHUNKS_${isConnected ? 'lockup' : 'anon'}.png`}
                        loading="lazy"
                        alt="Skelephunks"
                        className="image"
                        data-w-id="31a62934-7dee-bf92-5793-1bf374967db9"
                    />
                </Link>

                <h3 className="mint-title" data-w-id="b93b5472-5113-27f5-b1ae-1a2a5a5a52c2">
                    {subtitle}
                </h3>
            </div>
            <div className="hud wallet">
                {isConnected && <>
                    <div className="address">
                        {wallet.substring(0, 13)}
                    </div>
                    {maxMinted ?
                        <div className="yours">Max Minted</div> : <div className="yours">{walletBalance} {testnet ? '†' : ''}{balanceData?.symbol}</div>
                    }
                </>}
                {defined(gasPrice) && <div className="info eth">
                    Gas is {gasPrice.substring(0, 4)} {testnet ? '†' : ''}GWEI
                </div>}
                {defined(numBones, numFragments) && <Link to="/ossuary"><div className="info welcome">
                    🦴 <span style={{ color: 'white' }}>{numBones}</span> | ✨ <span style={{ color: 'white' }}>{numFragments}</span>
                </div></Link>}
                <div className="wc-button">
                    <ConnectButton />
                </div>
                <div className="iconnav">
                    <a href="https://discord.gg/YS7FgPudTr" target="_blank">
                        <img src="images/28-283551_discord-icon-png.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://opensea.io/collection/skelephunks-official" target="_blank">
                        <img src="images/OS.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://twitter.com/skelephunks" target="_blank">
                        <img src="images/2021-Twitter-logo---white.png" loading="lazy" alt="" className="social" data-ix="new-interaction" />
                    </a>
                </div>
            </div>
        </div >
    </>)
}