import equiv from "../../../../../util/equiv";
import ActionButton from "./ActionButton";

export default function TxButton({ style, disabled, label, loading, onClick, status }) {
    //console.log('>>> TxButton')
    // console.log(`${label.toString()} :: ${status}`)
    return (
        <ActionButton
            style={style}
            disabled={disabled}
            onClick={onClick}
            label={
                equiv(status, 'signing') ? '✍️ please sign the transaction'
                    : equiv(status, 'loading') ? loading
                        : label}
            className={status}
        />
    )
}