import defined from "../../../util/defined";
import useSmartContract from "../useSmartContract";
import useRedraw from "../../useRedraw";

// Array to store metadata
const metadata = [];
// Array to store metadata URIs for queued requests
const metaq = [];
// Flag to trigger refresh
let refresh;

/**
 * Custom hook for managing an NFT collection, extends useSmartContract with NFT functionality
 * @param {object} contract The contract object representing the NFT collection.
 * @returns {object} An object containing the smart contract and the collection.
 */
export default function useNFTCollection(contract) {
    //console.log('>>> useNFTCollection')
    // Extends smart contract
    const { contract: collection } = useSmartContract(contract);
    const { redraw } = useRedraw();

    // Extend with function to get metadata for a given token ID (Requires 721Metadata)
    collection.getMetadata = (id) => {
        // console.log(`getMetadata ${id}`);
        const uri = collection.tokenURI(id);
        let meta;
        if (defined(uri)) {
            // console.log(`uri ${uri}`);
            meta = metadata[uri];
            if (!defined(meta)) {
                meta = metadata[uri] = {};
                if (metaq.indexOf(uri) === -1) {
                    metaq.push(uri)
                    let error;
                    // Fetch metadata from URI
                    fetch(uri).then((data) => {
                        data.json().then((json) => {
                            // Convert attributes to traits
                            json.traits = {}
                            json.attributes.forEach(att => json.traits[att.trait_type] = att.value);
                            metadata[uri] = json;
                            refresh = true;
                            redraw();
                        }).catch((e) => {
                            error = e;
                        }).finally(() => {
                            // Remove URI from queue after processing
                            metaq.splice(metaq.indexOf(uri), 1);
                            // Trigger redraw if all metadata requests are processed and a refresh is needed
                            if (metaq.length === 0 && refresh) {
                                refresh = false;
                                redraw();
                            }
                        })
                    });
                }
            }
        }
        return meta
    }

    // Extend with function to get tokens owned by a specific wallet (Requires IEIP721Enumerable)
    collection.tokensOfOwner = (wallet, loadAmt, loadStart) => {
        let balance, tokenIDs;
        loadStart = loadStart || 0;
        if (defined(wallet)) {
            let ids = []
            balance = collection.balanceOf(wallet);
            const max = defined(balance) ? defined(loadAmt) ? Math.min(loadAmt - loadStart, balance) : balance : 0;
            let buffer = 22 - Math.min(15, Math.floor(max / 8));
            for (let i = loadStart; i < max; i++) {
                const id = collection.tokenOfOwnerByIndex(wallet, i);
                if (!defined(id)) {
                    if (--buffer == 0) { //20 - 13 
                        break;
                    }
                } else {
                    ids.push(id);
                }
            }
            tokenIDs = ids?.length > 0 ? ids : tokenIDs;
        }
        return { wallet, balance, tokenIDs }
    }
    return { collection }
}
