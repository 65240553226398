
import { sepolia, mainnet } from '@wagmi/core/chains'
import { walletConnect } from '@wagmi/connectors'
import SKELEPHUNKS_ABI from '../abi/skele/SkelephunksABI.json';
import SKELEKEYS_ABI from '../abi/skele/SkeletonKeysABI.json';
import GRAVEYARD_ABI from '../abi/skele/GraveyardABI.json';
import DROP_ABI from '../abi/skele/DropABI.json';
import OBITUARY_ABI from '../abi/skele/ObituaryABI.json';
import GRIDTAG_ABI from '../abi/grid/GRiDTagABI.json';
import GRIDIDENTITY_ABI from '../abi/grid/GRiDIdentityABI.json';
import GRIDSTORE_ABI from '../abi/grid/GRiDStoreABI.json';
import CATACOMBS_ABI from '../abi/skele/CatacombsABI.json';
import REMAINS_ABI from '../abi/skele/RemainsABI.json';
import OSSUARY_ABI from '../abi/skele/OssuaryABI.json';
import SKELEMARKET_ABI from '../abi/skele/SkeleMarketABI.json';
import YOLO_ABI from '../abi/skele/YOLOABI.json';
import { createConfig, createStorage, http, webSocket } from 'wagmi';
import { defaultWagmiConfig } from '@web3modal/wagmi';
import { Link } from 'react-router-dom';


const
    gun = {
        sepolia: {
            peer: 'https://s-gun.data.skelephunks.com:7383/gun',
            key: 'r0eV0VApfhv2JKr9q16ZFgtCMSd9M49zZHoI8fO12sU.70flG_FT7bQ8oj4-264LNAtdKoW5MrkYfPONbPqhRN8',
            node: 'Skelephunks_SEPOLIA'
        },
        mainnet: {
            peer: 'https://gun.data.skelephunks.com:5383/gun',
            key: '6Em_0xxGwMKDdcLZ0td8RiVim-Jpj405VCbrPdtsHpc.jRQiJUKaeJ6zQXlxji35FOeKHu-bv8nHSbE-jHzPaoQ',
            node: 'Skelephunks_MAINNET'
        }
    },
    OVERRIDE = null,//'0xeA9319B22644D4D0D44bD498da6492b33aBBf287',// 
    ENV = 'sepolia', //'mainnet',//
    addresses = {
        sepolia: {
            skelephunks: '0x218a56135774A6fd1a2f20Bc4783793E039D71bE',
            skelekeys: '0xDc45EB6d08c5D0094131181B3ADBF162b89D2e2C',
            graveyard: '0xa2E84d714963da56650a18aE5d53A18465A3FCcB',
            skeledropv3: '0x05703c63acA221a32B7F77209AF3201d9fa179aE',
            catacombs: '0xf4137493db3EB1eF539E69405aee392E1599C922',
            gridtag: '0x538f43a38F59d0B17e992836cef9366ACf35e68a',
            grididentity: '0xCd8391e5F41ca1a20CAdDdcD6FB77f7050c002f5',
            gridstore: '0xa8DD3d8Ab8f9E1c70a833e16a861412e63fFeA5F',
            obituary: '0x29303982b9Fe438dd31B4Ea6A168aCD2B0013457',
            remains: '0x87B31fe6e7a3C786f0ff1D4Ea893Fa6Ce7f0f8b4',
            ossuary: '0xd1f78df89605db523D25C64aAD01430aF5771C09',
            skelemarket: '0x1142f3Cc1D8137Df9e09E252ABB11e183e5D97Be',
            yolo: '0x2A623a754Ca507B0746D46104E35877Ac84354a9',
        },
        mainnet: {
            skelephunks: '0x7db8cD89308A295bb2D7F809B05DB6389e9a6d88',
            skelekeys: '0xbcAF0710fd84BCf7452b50308Bd3627D2DcBe5D4',
            graveyard: '0x3b52AC11c612a74f6F1a11AA448340BEE1fed454',
            skeledropv3: '0xb88b1E6E7d2834f3B785E64a0944D251A0A3A2BE',
            // catacombs: '0x952c959d962E79E190DD0e8689D1FAA3c7CC0BBe',
            // tag: '0x5d6e594Ad52Bb948FD56b951Ee8FDB1F2C2965fD',
            //identity: '0x96521c9b2Cb84aB36fEA455C4B3C14BA1403c9de',
            // store: '0x710ADa0Eb68a603E9554094E6156977473c3FD07',
            //obituary: '0x45A12b7c459cC7D0872219dd5985773c18c950f9',
        }
    },
    env = ENV,
    testnet = env !== 'mainnet',
    override = OVERRIDE,

    projectId = '6b12006946489aaacb1373c107c932e9',
    metadata = {
        name: 'Skelephunks',
        description: 'Skelephunks dApp',
        url: 'https://app.skelephunks.com',//'http://localhost:3000',//
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    },

    wagmiChains = env === 'sepolia' ? [sepolia, mainnet] : [mainnet],
    modalChains = env === 'sepolia' ? [sepolia] : [mainnet],
    wcConfig = defaultWagmiConfig({
        chains: wagmiChains,
        metadata,
        projectId
    }),

    alchemyConfig = createConfig({
        chains: wagmiChains,
        // storage: createStorage({ storage: window.localStorage }),
        connectors: [
            walletConnect({
                projectId,
                metadata
            })
        ],
        transports: {
            [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/rCXZCUTBjVie0IKWo_5WtSW4u3tpdCAK'),
            [sepolia.id]: http('https://eth-sepolia.g.alchemy.com/v2/uDPbvFyy-AeBQxerej_QFyYeTYN_iMW7'),
        },
    }),
    eventsConfig = createConfig({
        chains: wagmiChains,
        storage: createStorage({ storage: window.localStorage }),
        transports: {
            [mainnet.id]: http('https://eth.public-rpc.com'),
            [sepolia.id]: http('https://rpc.sepolia.org'),
        },
    })

export default function getENV() {
    //console.log('>>> getENV')
    const
        etherscan = `https://${testnet ? env + '.' : ''}etherscan.io`,
        opensea = `https://${testnet ? 'testnets.' : ''}opensea.io`,
        osAPI = `https://api.opensea.io/api/v1`,
        osNet = testnet || 'ethereum';

    return {
        projectId,
        override,
        testnet,
        gun: gun[ENV],
        status: {
            claims: true,
            network: true,
        },
        collection: {
            startingIndex: 1,
            provenanceFile: 'https://arweave.net/08A-xK3YjY2wTxBWAnaL_Uw-u2yuYCb0rk2kfdxV6xw/provenance/skelephunks/7afa40ff5176f2b872c12fe54937b68b6f9d720fc02a2e84eac093dde56d1a89.csv'
        },

        modules: {
            skelephunks: {
                icon: "💀",
                contract: {
                    address: addresses[env].skelephunks,
                    abi: SKELEPHUNKS_ABI
                }
            },
            keys: {
                icon: "🗝️",
                contract: {
                    address: addresses[env].skelekeys,
                    abi: SKELEKEYS_ABI
                }
            },
            graveyard: {
                icon: "🪦",
                contract: {
                    address: addresses[env].graveyard,
                    abi: GRAVEYARD_ABI
                }
            },
            drop: {
                icon: "💧",
                contract: {
                    address: addresses[env].skeledropv3,
                    abi: DROP_ABI
                }
            },
            catacombs: {
                icon: "⚰️",
                contract: {
                    address: addresses[env].catacombs,
                    abi: CATACOMBS_ABI
                }
            },
            gridTag: {
                icon: "🏷️",
                contract: {
                    address: addresses[env].gridtag,
                    abi: GRIDTAG_ABI
                }
            },
            gridIdentity: {
                icon: "👤",
                contract: {
                    address: addresses[env].grididentity,
                    abi: GRIDIDENTITY_ABI
                }
            },
            gridStore: {
                icon: "📦",
                contract: {
                    address: addresses[env].gridstore,
                    abi: GRIDSTORE_ABI
                }
            },

            obituary: {
                icon: "📰",
                contract: {
                    address: addresses[env].obituary,
                    abi: OBITUARY_ABI
                }
            },

            remains: {
                icon: "🦴",
                contract: {
                    address: addresses[env].remains,
                    abi: REMAINS_ABI
                }
            },
            ossuary: {
                icon: "⚖️",
                contract: {
                    address: addresses[env].ossuary,
                    abi: OSSUARY_ABI

                }
            },
            skeleMarket: {
                icon: "🛒",
                contract: {
                    address: addresses[env].skelemarket,
                    abi: SKELEMARKET_ABI
                }
            },
            yolo: {
                icon: "🎲",
                contract: {
                    address: addresses[env].yolo,
                    abi: YOLO_ABI
                }
            },

        },
        chains: {
            wagmi: wagmiChains,
            modal: modalChains,
        },
        config: {
            wagmi: wcConfig,
            events: alchemyConfig
        },
        etherscan: {
            home: etherscan,
            tx: hex => `${etherscan}/tx/${hex}`,
            address: hex => `${etherscan}/address/${hex}`,
            token: hex => `${etherscan}/token/${hex}`
        },
        opensea: {
            home: opensea,
            collection: hex => `${opensea}/collection/${hex}`,
            skelephunks: `${opensea}/collection/skelephunks-official`,
            token: (collection, id) => `${opensea}/assets/${osNet}/${collection}/${Number(id)}`,
            user: hex => `${opensea}/${hex}`,
            api: osAPI,
            refresh: (collection, id) => fetch(`${osAPI}/assets/${collection}/${Number(id)}?force_update=true`)
        },
        merkle: {
            phunks: '../data/phunks.csv',
            allow: '../data/allow.csv',
            drops: '../data/drop.csv'
        },
        promo: {
            // drop: {
            //     nav: "Get up to 3 free mints",
            //     button: "Sign up for your chance to win",
            //      icon: "💧"
            //     link: "https://spearmint.xyz/p/glass-punk-PIVaway"
            // }
            profile: {
                message: "Profiles are live! open a token to make it your PFP and claim your SkeleTag",
                nav: "View/Modify Profile",
                button: "Profile",
                icon: "👤",
                link: "#"
            },
            rewards: {
                message: <>Your Profile is live! Manage your profile rewards in <Link to="/ossuary">the Ossuary</Link></>,
                nav: "Manage Rewards Profile",
                button: "Rewards",
                icon: "⚖️",
                link: "#"
            }
        }
    }
}