import { Link } from "react-router-dom";
import ActionButton from "./ActionButton";
import { Navigate } from "react-router";

export default function LinkButton({ style, label, to, className, disabled }) {
    return (
        <Link to={to}>
            <ActionButton
                style={style}
                label={label}
                className={className}
                disabled={disabled}
            />
        </Link>
    )
}