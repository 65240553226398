import GAD from "../../../../util/GAD";
import defined from "../../../../util/defined";
import getENV from "../../../../util/getENV";
import useSkeleGun from "../../../useSkeleGun";
import useNFTCollection from "../useNFTCollection";

export default function useSkelephunks(contractOverride) {
    const { modules: { skelephunks: { contract } } } = getENV();
    const { collection: skelephunks } = useNFTCollection(contractOverride || contract);
    const getMetadata = skelephunks.getMetadata;
    const { getToken } = useSkeleGun()
    skelephunks.getMetadata = (id) => {
        const gunToken = getToken(id);
        if (defined(gunToken?.metadata)) return gunToken.metadata;
        const meta = getMetadata(id);
        if (defined(meta)) skelephunks.getTokenInfo(id) // preload tokenInfo
        return meta
    }
    skelephunks.getTokenInfo = (id) => {
        const gunToken = getToken(id);
        const gad = gunToken?.genderAndDirection || skelephunks.getGenderAndDirection(id),
            minter = gunToken?.minterOf || skelephunks.minterOf(id),
            owner = gunToken?.ownerOf || skelephunks.ownerOf(id),
            timestamp = gunToken?.mintedAt || skelephunks.mintedAt(id),
            genderAndDirection = gad,
            gender = GAD.genderOf(gad),
            direction = GAD.directionOf(gad)
        return {
            id,
            minter,
            owner,
            timestamp,
            genderAndDirection,
            gender,
            direction,
        }
    }
    return { skelephunks }
}